@import '_variables.scss';

.mg-top-auto { margin-top: auto; }
.mg-top-0 { margin-top: 0px; }
.mg-top-8 { margin-top: 8px; }
.mg-top-10 { margin-top: 10px; }
.mg-top-12 { margin-top: 12px; }
.mg-top-13 { margin-top: 13px; }
.mg-top-15 { margin-top: 15px; }
.mg-top-16 { margin-top: 16px; }
.mg-top-20 { margin-top: 20px; }
.mg-top-24 { margin-top: 24px; }
.mg-top-25 { margin-top: 25px; }
.mg-top-30 { margin-top: 30px; }
.mg-top-40 { margin-top: 40px; }
.mg-top-50 { margin-top: 50px; }
.mg-top-60 { margin-top: 60px; }
.mg-btm-0 { margin-bottom: 0 !important; }
.mg-btm-5 { margin-bottom: 5px; }
.mg-btm-8 { margin-bottom: 8px; }
.mg-btm-10 { margin-bottom: 10px; }
.mg-btm-12 { margin-bottom:12px;}
.mg-btm-15 { margin-bottom: 15px; }
.mg-btm-16 { margin-bottom: 16px; }
.mg-btm-18 { margin-bottom: 18px; }
.mg-btm-20 { margin-bottom: 20px; }
.mg-btm-24 { margin-bottom: 24px; }
.mg-btm-25 { margin-bottom: 25px; }
.mg-btm-32 { margin-bottom: 32px; }
.mg-btm-40 { margin-bottom: 40px; }
.mg-btm-47 { margin-bottom: 47px;}
.mg-btm-50 { margin-bottom: 50px;}
.mg-topbtm-8 { margin: 8px 0; }
.mg-topbtm-10 { margin: 10px 0; }
.mg-topbtm-16 { margin: 16px 0; }
.mg-topbtm-20 { margin: 20px 0; }
.mg-topbtm-30 { margin: 30px 0; }
.mg-topbtm-40 { margin: 40px 0; }
.mg-rt-0 { margin-right: 0px; }
.mg-rt-10 { margin-right: 10px; }
.mg-rt-12 { margin-right: 12px; }
.mg-rt-8 { margin-right:8px; }
.mg-rt-20 { margin-right: 20px; }
.mg-rt-auto { margin-right: auto; }
.mg-lft-0 { margin-left: 0px; }
.mg-lft-8 { margin-left:8px; }
.mg-lft-10 { margin-left: 10px; }
.mg-lft-12 { margin-left: 12px; }
.mg-lft-16 { margin-left: 16px; }
.mg-lft-20 { margin-left: 20px; }
.mg-lft-30 { margin-left: 30px; }
.mg-lft-auto { margin-left: auto; }
.mg-sides-0 { margin-left: 0px; margin-right: 0px; }
.mg-sides-8 { margin-left: 8px; margin-right: 8px; }
.full-width { width: 100%; }
.row.no-gutters {
    // margin-right: 0;
    // margin-left: 0;
    > [class*=" col-"], > [class^="col-"], {
        padding-right: 0;
        padding-left: 0;
    }
}
.pd-lt-rt-0 {padding-left:0px; padding-right:0px; }
.pd-lt-rt-5 {padding:0 5px; }
.pd-lft-0 { padding-left: 0; }
.pd-lft-5 { padding-left: 5px; }
.pd-lft-10 { padding-left: 10px; }
.pd-lft-15 { padding-left: 15px; }
.pd-lt-0 { padding-left: 0; }
.pd-rt-0 { padding-right: 0; }
.pd-rt-5 { padding-right: 5px; }
.pd-rt-8 { padding-right:8px; }
.pd-rt-10 { padding-right: 10px; }
.pd-rt-15 { padding-right:15px; }
.pd-top-15 {padding-top:15px;}
.pd-top-16 {padding-top:16px !important;}
.pd-top-25 {padding-top:25px;}
/*.pd-top-50 {padding-bottom:50px;}*/
.pd-bot-25 {padding-bottom:25px;}
.pd-bot-30 {padding-bottom:30px;}
.pd-bot-50 {padding-bottom:70px;}
.pd-bot-70 {padding-bottom:70px;}
.pd-top-50 {padding-top:50px;}
.pd-top-60 {padding-top:60px;}
.pd-bot-0 { padding-bottom:0 !important; }
.pd-top-0 { padding-top:0 !important; }
.pd-top-5 {padding-top:5px;}
.pd-bot-5 {padding-bottom:5px;}
.pd-top-6 {padding-top:6px;}
.pd-bot-6 {padding-bottom:6px;}
.pd-top-7 {padding-top:7px;}
.pd-bot-7 {padding-bottom:7px;}
.pd-top-10 {padding-top:10px;}
.pd-top-13 {padding-top:13px;}
.pd-top-20 {padding-top:20px;}
.pd-bot-10 {padding-bottom:10px;}
.pd-bot-15 {padding-bottom:15px;}
.pd-bot-100 {padding-bottom:100px;}
.pd-lft-23 { padding-left: 23px; }
.pd-sides-0 {
    padding-left: 0px;
    padding-right: 0px;
}
.pd-sides-5 {
    padding-left: 5px;
    padding-right: 5px;
}
.pd-sides-8 {
    padding-left: 8px;
    padding-right: 8px;
}
.pd-sides-10 {
    padding-left: 10px;
    padding-right: 10px;
}
.pd-topbtm-0 {
    padding-top:0;
    padding-bottom:0;
}
.pd-topbtm-20 {
   padding-top:20px;
   padding-bottom:20px;
}
.pd-topbtm-10 {
    padding-top:10px;
    padding-bottom:10px;
 }
.pd-none { padding: 0; }
.flt-right { float: right; }
.flt-left { float: left; }
.display-ib { display: inline-block; }
.display-if { display: inline-flex; }
.display-block { display:block; }
.display-flex { display: flex; }
.display-none { display: none; }
.ver-align-top { vertical-align:top; }
.ver-align-mdl { vertical-align:middle; }
.txt-algn-lft { text-align: left; }
.txt-algn-rt { text-align: right; }
.txt-algn-ct { text-align:center; }

/************************************************** flex utils **************************************************/

.flex-1 {flex: 1;}
.justify-between {justify-content: space-between;}
.gap-1 {gap: 5px;}

/************************************************** end flex utils **************************************************/
.pos-rel { position: relative; }
.break-word { word-break: break-word;}

.overflow-auto-at-180 {
    max-height: 180px;
    overflow-y: auto;
}
.h-180 { height: 180px; }

/**************************************************font size***********************/
.ft-wt-nl {font-weight:normal;}
.ft-sz-11 { font-size:11px;}
.ft-sz-12 { font-size:12px !important;}
.ft-sz-13 { font-size:13px;}
.ft-sz-14 { font-size:14px;}
.ft-sz-16 { font-size:16px !important;}
.ft-sz-20 { font-size:20px;}
.ft-sz-32 { font-size:32px;}
.ft-bold { font-weight:bold;}

/**************************************************width***********************/

.max-width-60 { max-width:60px;}
.max-width-100-prcnt { max-width:100%;}

@include breakpointMax(768){
    .pd-mb-btm-30 {padding-bottom:30px;}
    .pd-mb-top-30 {padding-top:30px;}
}
@include breakpointlandscape (823){
    .pd-mb-btm-30 {padding-bottom:30px;}
    .pd-mb-top-30 {padding-top:30px;}
}
@include breakpointMax(480){
    .pd-bot-50 {padding-bottom:50px;}
}