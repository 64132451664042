$theme-color: #0061AC;
$theme-light-blue: #B9E3F4;
$theme-white: #FFFFFF;

$theme-background: #EFF3F6; 
$theme-input-background: #F2F6F9; 
$theme-activityCard-background: #F7F9FB;


$theme-border: #B9C8D1; 
$theme-input-border: #A3B5BF; 
$theme-activityCard-border: #A3B5BF; 


// :export {
//     themeName: $theme-color;
//     themeColor: $theme-background;
// }