@import '_mixins.scss';
@import '_variables.scss';

// this is for bootstrap theme overrides. not sure if this will stay
.bg-dyna-light {
    background-color: #FFFFFF;
}

.dyna-box {
    background: #FFFFFF;
    /* Light Gray $theme-border */
    border: 1px solid $theme-border;
    box-sizing: border-box;
    border-radius: 3px;
    padding: 15px;
}

.dyna-container {
    // overriding bootstrap container. this could be catostrophic.
    @include breakpointMin(1200) {
        max-width: 1400px;
    } 
    @include breakpointMinMax(768, 991) {
        max-width:900px;
    }
}

.dyna-container-sm {
    @include breakpointMin(1200) {
        max-width: 1200px;
    }  
}

.dyna-btn {
    border: 1px solid #006188;
    box-sizing: border-box;
    border-radius: 3px;
    font-family: Inter UI;
    font-style: normal;
    font-weight: normal;
    line-height: normal;
    font-size: 14px;
    text-align: center;
    letter-spacing: -0.02em;
    color: #006188;
    background-color: #FFF;
    width: 100%;
    padding: 5px;
    height: 32px;
    cursor: pointer; 
    &:hover {
        border: 1px solid;
        background-color: #006188;
        border-color: #006188;
        color:#fff;
        transition: background-color .25s;
    }
    @include breakpointMax(1024) {
        font-size:13px;
    }
    &.dyna-btn_mobile {
        padding: 8px;
        font-size: 12px;
    }
}
.dyna-btn-gray.auto-width {
    width:auto; 
}

.dyna-btn-gray {
    border: 1px solid #B3B3B3;
    box-sizing: border-box;
    border-radius: 3px;
    font-family: Inter UI;
    font-style: normal;
    font-weight: normal;
    line-height: normal;
    font-size:12px;
    text-align: center;
    //letter-spacing: -0.02em;
    color: #555555;
    background-color: #FFF;
    width:100%;
    padding:8px 12px;
    cursor: pointer; 
    height: 32px;
    //&:hover {
        //border: 1px solid;
        //background-color: #006188;
        //border-color: #006188;
        //color:#fff;
        //transition: background-color .25s;
    //}
    @include breakpointMax(1024) {
        padding:8px 8px;
    }
}



.dyna-form {
    font-size:12px;
    label {
        text-align: right;
        @include breakpointMax(575) {
            text-align: left;
        } 
    }
    %dyna-input {
        background-color: $theme-input-background;
        border: 0;
        border-radius: 3px; 
        font-size: 12px;
    }
    textarea { @extend %dyna-input }
    input {
        @extend %dyna-input;
        height: 32px;
        border: 1px solid $theme-input-border;
        border-radius: 3px;
    }
}

.dyna-filter-bar {
    height: 64px;
    width: 100%;
    background-color: #fff;
    border-top: 1px solid #D8D8D8;
    border-bottom: 1px solid #D8D8D8;
    display: flex;
    align-items: center;
    padding: 0.5rem 1rem;
    #listingFilterPopover, #receivedDateFilterPopover {
        height: 32px;
    }
    &.mobile {
        padding: 0px 5px;
        height: auto;
        .dyna-filter-bar_left { width: 100%; }
        #listingFilterPopover, #receivedDateFilterPopover {
            height: 40px;
            flex-grow: 1;
            border-radius: 0;
            border: 0;
            span {
                overflow: hidden; 
                text-overflow: ellipsis;
                white-space: nowrap;
                max-width: 100px;
                padding: 7px;
                @include breakpointMin(360) {
                    max-width: 110px;
                }
                @include breakpointMin(410) {
                    max-width: 140px;
                }
            }
        }
        #receivedDateFilterPopover { border-left: 1px solid $theme-border; }
        #receivedDateContainer { flex-grow: 1;}
        .dyna-popoverText.open {
            padding: 5px;
            border-radius: 0;
        }
        .mobile-back {
            border-right: 1px solid $theme-input-border;
            border-radius: 0;
            padding-left: 5px;
            padding-right: 5px;
        }
        .mobile-map {
            border-left: 1px solid $theme-input-border;
            padding-left: 10px; 
            border-radius: 0;
        }
    }
    .dyna-filter-bar_left {
        a, button {
            display: flex;
            align-items: center;
            img {
                padding-right: 5px;
            }
        }
    }
    .dyna-filter-bar_right {
        margin-left: auto;
        img {
            cursor: pointer;
        }
    }
}

.dyna-login {
    input {
        background: #005E7A;
        border:none;
        text-align:center;
        padding:10px;
        width:100%;
        border-radius: 3px;
        color:#fff; 
        cursor: pointer;
        line-height: 10px;
        height: 40px;
    }    
}

.btn-link {
    font-family: Inter UI;
    color: #006188;
    font-size: 14px;
    border: 0;
    padding: 0;
    background: transparent;
    cursor: pointer;
    &:focus {
        outline-color: #0078A8;
    }
    &.btn-link-bold {
        font-weight: 600;
    }
    &.btn-link-sm {
        font-size: 12px;
    }
    &.btn-link-lg {
        font-size: 16px;
    }
    h4 {
        color:#006188;
    }
}


%dyna-heading {
    // font-family: Ramaraja;
    font-style: normal;
    font-weight: bold;
    line-height: normal;
    letter-spacing: 0.02em;
    color: #333333;
    margin: 0;
 }

h5 {
    @extend %dyna-heading;
    font-size: 16px;
}
h4 {
    @extend %dyna-heading;
    font-size: 20px;
}
h3 {
    @extend %dyna-heading;
    font-size: 24px;
}
h2 {
    @extend %dyna-heading;
    font-size: 32px;
}
h1 {
    @extend %dyna-heading;
    font-size: 40px;
}

a {
    color: #006188;
    font-size: 14px;
}

.dyna-divider {
    height: 1px;
    width:100%;
    background: $theme-border;
    margin: 16px 0;
}
.dyna-divider-darkbg {
    background: #333333;
}
.dyna-popoverText {
    font-size: 12px;
    display: flex;
    align-items: center;
    padding: 5px;
    cursor: pointer;
    &.open {
        background-color: $theme-background;
        border: 1px solid $theme-border;
        border-radius: 3px;
    }
    &.popoverText_border {
        border: 1px solid $theme-border;
        border-radius: 3px;
    }
    &.folder-name {
        font-weight: 600;
        font-size: 18px;
        padding-top: 0;
        padding-bottom: 0;
    }
}
button.dyna-popoverText {
    border: 0;
    background: none;
    &:focus { outline-color: #0078A8; }
}
.dyna-popoverText.pd-lt-rt-5 {
    padding: 0 5px;
}
.dyna-popoverBody {
    .btn-link {
        color: #555555;
        padding: 10px 60px 10px 10px;
        font-family: Inter UI;
    }
    .divide + .divide { 
        border-top: 1px solid $theme-border;
    }
}

.dyna-new {
    border-radius: 3px;
    color: #FFFFFF;
    background-color: #006188;
    display: inline-table;
}

.dyna-dropdown {
    font-size: 14px;
    display: flex;
    align-items: center;
    padding: 9px 12px 8px;
    cursor: pointer;
    &.open {
        background-color: #FFFFFF;
        border-radius: 3px;
        border: 1px solid $theme-input-border;
    }
    .dropdown-toggle,
    .btn-secondary:not(:disabled):not(.disabled):active, 
    .btn-secondary:not(:disabled):not(.disabled).active, 
    .show > .btn-secondary.dropdown-toggle,
    .btn-secondary:not(:disabled):not(.disabled):active:focus, 
    .dropdown-toggle:not(:disabled):not(.disabled).active:focus, 
    .show > .btn-secondary.dropdown-toggle:focus,
    .show > .btn-secondary.dropdown-toggle {
        background-color: transparent !important;
        border-color: transparent !important;
        color: inherit !important;
        width: 100% !important;
        // box-shadow: none !important;
        text-align: left !important;
        display: inline-flex !important;
        padding: 0 !important;
        height: auto !important;
        line-height: normal !important;
    }
    .dropdown-toggle::after{
        display: none !important;
    }
    .dropdown-menu.show {
        top: 8px !important;
        left: -12px !important;
        min-width: 100% !important;
        font-size: 12px;
        font-weight: normal;
        font-style: normal;
        line-height: normal;
        max-height: 264px;
        overflow-y: auto;
    }
    .dropdown-item {
        cursor: pointer;
        font-weight: normal;
        font-size: 14px;
        padding: 8px 15px;
        color: #555555;
        border: none;
    }
    .dropdown-item:hover, .dropdown-item:focus {
        color: #fff;
        background-color: #006188;
        border: none;
    }
    .dropdown-item.active, .dropdown-item:active {
        color: #333333 !important;
        font-weight: 600 !important;
        background-color: #fff !important;
        border: none;
    }
}

 body {
    // font-family: Inter UI;
    font-family: 'Inter UI', sans-serif;
    font-style:  normal;
    background-color: transparent;
 }

 // this is to fix () not getting underlined on hover
 * {
    text-decoration-skip-ink: none;
  }

  // this is overriding margin added by bootstrap
  ul {
      margin-bottom: 0;
  }

// override svg vertical align added by bootstrap
svg {
    vertical-align: initial;
    // override svg focus outline
    &:focus { outline-color: #0078A8; }
}

a:focus,
input:focus,
button:focus,
span:focus,
div:focus { outline-color: #0078A8 !important; } 

.backToTop {
    position: fixed;
    z-index: 1001;
    right: 20px;
    bottom: 32px;
    cursor: pointer;
}

.msgBubble {
    position: fixed;
    z-index: 1001;
    right: 20px;
    bottom: 32px;
    cursor: pointer;
}

 // override bootstrap tooltip
 .tooltip-inner {
    padding: 12px;
    background-color: #333;
    box-shadow: 0px 2px 3px rgba(0, 0, 0, 0.25), 0px -1px 2px rgba(0, 0, 0, 0.1);
 }
 .tooltip .arrow::before {
    border-top-color: #333;
 }
//https://github.com/reactstrap/reactstrap/issues/1728 trying to resovle flicker
 .tooltip,
 .pointer-events-none {
    pointer-events: none;
}

 // override browser blue border
 input:focus, input:active {
     outline-color: #555555;
 }

 // override modal carousel arrows
 .control-arrow:before {
    border-top: 20px solid transparent !important;
    border-bottom: 20px solid transparent !important;
 }
 .control-next.control-arrow:before {
    border-left: 20px solid #fff !important;
 }
 .control-prev.control-arrow:before {
    border-right: 20px solid #fff !important;
}
.control-arrow{
    opacity: 0.75 !important;
    background-color: black !important;
}
.control-arrow:hover{
    opacity: 0.6 !important;
    background-color: black !important;
}

.dyna-pagination {
   float:right; 
}
.dyna-pagination .pagination  { 
    margin:0px;
}
.dyna-pagination .pagination li {
    margin:0 2px; 
}
.dyna-pagination .pagination .page-item .page-link {
    padding: 0.22rem 0.3rem;
    color: #333333;
    border-radius:3px;
    line-height: normal;
}
.dyna-pagination .pagination .page-item:first-child .page-link {
    padding: 0.22rem 0.6rem;
}
.dyna-pagination .pagination .page-item:last-child .page-link {
    padding: 0.22rem 0.6rem;
}
.dyna-pagination .pagination .page-item.active .page-link {
    background-color: #006188;
    color:#fff;
    border-color: #006188; 
}
// .dyna-pagination .pagination .page-item .page-link:focus {
//     box-shadow:inherit;
// } 

.modal-backdrop.show{
    opacity: .75;
}
.modal {
    -webkit-overflow-scrolling: touch; // smooth scroll for iphone
}
.dyna-img-round {
    min-height: 100%;
    min-width: 100%;
    width: auto;
    height: auto;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
}
.dyna-bg-img-round {
    width: 60px;
    height: 60px;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
}
.dyna-photo-width {
    height: 60px;
    width: 60px;
    border-radius: 50%;
    overflow: hidden;
    position: relative; 
    margin: 0 auto;

}
.dyna-color-555 {
    color:#555555; 
 }
.pd-topbtm-16 {
    padding-top:16px;
    padding-bottom:16px;  
    @include breakpointMax(576)
    {
        padding-top:12px;
        padding-bottom:12px;  
    }
}

.dyna-popup-dropdown {
    font-size: 14px;  
    padding: 0;
    // margin: -0.5rem -0.75rem;
    .dyna-item {
        display: block;        
        cursor: pointer;
        font-weight: normal;
        padding: 8px 15px;
        color: #555555;
        border: none;
        &:first-child {
            margin-top: 0.5rem;
        }
        &:last-child {
            margin-bottom: 0.5rem;
        }
    }
    .dyna-item:hover, .dyna-item:focus {
        color: #fff;
        background-color: #006188;
        border: none;
    }
}

.no_background {
    background: none !important;
}

svg { pointer-events: all; }

.dyna-hidden {
    position: absolute;
    left: -10000px;
    width: 1px;
    height: 1px;
    overflow: hidden;
}

.btn-primary {
    background-color: #0078a8;
  }