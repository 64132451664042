/* unsuccesfully trying to override bootstrap navbar to be off-canvas */
/* removing this will revert to default bootstrap mobile nav */

@import 'shared/_mixins.scss';

.collapsing {
    -webkit-transition: none;
    transition: none;
    display: none;
  }
  
  @media (max-width: 991px) {
    #sidebar {
      position: absolute;
      width: 280px;
      top:100%;
      right:0;
      background-color: #fff;
      transition: all 0.25s ease-out;
      height: calc(100vh - 56px);
      z-index: 1025;
      text-align:left; 
    }
    .navbar-light .navbar-nav .nav-link.navbar-link-text.active:after {
       display:none;
    }
    .navbar-top-border {
      border-top:1px solid #c4c4c4 
    }
    .navbar-light .navbar-nav .nav-link {
      padding:0px !important ; 
      display:block !important;
    }
    .navbar-light .navbar-nav .nav-link.navbar-link-text:hover {
      background:inherit !important;
      border-radius: inherit !important; 
    }
    .navbar-light .navbar-nav .nav-item .navbar-pad {
      display:block !important;
      padding:10px 30px !important; 
    }
    #sidebar.collapse:not(.show) {
      right:100%;
    }
    .navbar-collapse.show {
      position: relative; 
    }
    .navbar-collapse.show:after{
      position: absolute;
      top: 0;
      height: 100%;
      content: "";
      background: #000;
      opacity: 0.5;
      width: 991px;
      z-index: -1;
      margin-left:-991px;
      @include breakpointlandscape (823){
        width:836px;
        margin-left:-836px;
        left:0;  
      }    
    }
  }
  /************************************/
  /* end navbar mobile override */

  /* fix vertical alignment for desktop navbar */
  @media (min-width: 992px) {
    .navbar-expand-md .navbar-nav {
      align-items: center;
      img {
        vertical-align: top;
      }
    }
  }

.navbar-logo-text {
    padding-left:10px;
    font-family: Inter UI;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    font-size: 20px;
    letter-spacing: -0.3px;
    color: #0061AC;
  }

  .navbar-logo {
    @media (min-width: 992px) {
      max-height: 30px;
    }
    @media (max-width: 991px) {
      max-height: 25px;
    }
    @media (max-width: 1016px) {
      max-width: 140px;
    }
  }

.navbar-light .navbar-nav .nav-link.navbar-link-text {
    font-family: Inter UI;
    font-style: normal;
    font-weight: normal;
    line-height: normal;
    font-size: 16px;
    letter-spacing: -0.02em;
    color: #333333;
    position: relative;
    display: inline-block;
    vertical-align:middle;
    max-height: 38px;
    &.active { 
      font-weight: bold;
      &:after {
        position: absolute;
        bottom:-10px;
        left:8px;
        height:2px;
        content: " ";
        width:calc(100% - 16px);
        background-color: currentColor;
      }
    }
    .navbar-icon-img {
      svg path { stroke: currentColor; }
    }
  //  &:hover {
  //     background: #EBEBEB;
  //     border-radius: 6px;
  //   }
      &:hover:not(.active) {
        &:after {
          position: absolute;
          bottom:-10px;
          left:8px;
          height:2px;
          content: " ";
          width:calc(100% - 16px);
          background-color: currentColor;
        }
      }
}
.navbar-light .navbar-nav .nav-link {
    padding:9px 8px;
}
.navbar-light .navbar-nav .nav-item .navbar-pad {
  padding-right:8px;
  padding-left:8px;
  display:inline-block; 
}
.navbar-light {
  .navbar-toggler {
    border:none;
    div {
      width: 30px;
      height: 2px;
      margin: 6px 0;
      border-radius: 50px;
    }
  }
  .navbar-toggler:focus {
    outline-color: #0078A8;
  }
}

.profilePopover_container {
  margin-left: auto;
  .dyna-popoverText {
    &.open {
      background-color: inherit;
      border: 1px solid currentColor;
      border-radius:6px;
      padding: 4px;
    }
    justify-content: center;
  }
}

#profilePopover {
  font-size: 16px;
  align-items: center;
  display: flex;
  color: inherit;
}

.profile {
  background-color: #419BF9;
  border-radius: 20px;
  width: 32px;
  height: 32px;
  color: #FFF;
  font-size: 16px;
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.profile-popoverBody {
  .btn-link {
    padding: 20px 60px 20px 10px;
  }
  .profile_name {
    font-size: 16px;
    display: flex;
    flex-direction: column;
    padding-left:10px;
    font-weight: 600;
  }
  .change_photo {
    padding: 0;
    font-size: 14px;
    color: #006188;
  }
}
.navbar-icon-img {
  float:left;
  padding-right:8px;  
}
.navbar-icon-img.no-pad-right{
  padding-right:0 
}
.profile_popup {
  box-shadow: 0 5px 10px rgba(0,0,0,.2);
}
.profile_popup .popover-body {
  padding:16px 0  5px 0; 
}
.profile_popup .popover-body .divide .profile-link {
  width:100%;
  padding:8px 15px;
  text-align:left; 
  line-height:normal; 
  text-decoration:none;
}
.profile_popup .popover-body .divide .profile-link:hover {
  background: #006188;
  color:#fff;
  border-radius: inherit; 
}