@mixin optional-at-root($sel) {
    @at-root #{if(not &, $sel, selector-append(&, $sel))} {
      @content;
    }
  }
  
  @mixin placeholder {
    @include optional-at-root('::-webkit-input-placeholder') {
      @content;
    }
  
    @include optional-at-root(':-moz-placeholder') {
      @content;
    }
  
    @include optional-at-root('::-moz-placeholder') {
      @content;
    }
  
    @include optional-at-root(':-ms-input-placeholder') {
      @content;
    }
  }

@mixin linearGradient($direction, $one, $two) { 
  background: -webkit-linear-gradient($direction, $one 0%, $two 100%);
  background: -moz-linear-gradient($direction, $one 0%, $two 100%);
  background: -o-linear-gradient($direction, $one 0%, $two 100%);
  background: linear-gradient(to $direction, $one 0%, $two 100%); 
}

@mixin breakpointMax($size) {
  @media only screen and (max-width : $size + px) { @content; }
}

@mixin breakpointMin($size) {
  @media only screen and (min-width : $size + px) { @content; }
}

@mixin breakpointMinMax($min, $max) {
  @media only screen and (min-width : $min + px) and (max-width: $max + px) { @content; }
}
@mixin breakpointlandscape($size) {
  @media only screen and (max-width : 823px) and (orientation:landscape) { @content; }
}

// target ie 10 and 11
@mixin target-oldIE {
  @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
    @content;
  }
}

@mixin imgicons {
  .imgicon {
      cursor: pointer;
      width: 20px;
      height: 20px;
      &.heart {
          background: url(../../images/icons/listing-buttons/heart.svg) no-repeat;
          &.selected {
              background: url(../../images/icons/listing-buttons/heart-selected.svg) no-repeat;
          }
          &:not(.selected):hover {
              background: url(../../images/icons/listing-buttons/heart-hover.svg) no-repeat;
          }
      }
      &.rejected {
          background: url(../../images/icons/listing-buttons/thumbsdown.svg) no-repeat;
          &.selected {
              background: url(../../images/icons/listing-buttons/thumbsdown-selected.svg) no-repeat;
          }
          &:not(.selected):hover {
              background: url(../../images/icons/listing-buttons/thumbsdown-hover.svg) no-repeat;
          }
      }
      &.chat {
          background: url(../../images/icons/listing-buttons/chat.svg) no-repeat;
          &.selected {
              background: url(../../images/icons/listing-buttons/chat-selected.svg) no-repeat;
          }
          &:not(.selected):hover {
              background: url(../../images/icons/listing-buttons/chat-hover.svg) no-repeat;
          }
      }
      &.maybe {
          background: url(../../images/icons/listing-buttons/maybe.svg) no-repeat;
          &.selected {
              background: url(../../images/icons/listing-buttons/maybe-selected.svg) no-repeat;
          }
          &:not(.selected):hover {
               background: url(../../images/icons/listing-buttons/maybe-hover.svg) no-repeat;
          }
      }
  }
}

@mixin statusColors {
  color: #006644;
  &.Active { background: #C2F7A9; }
  &.PEND,
  &.Pending { 
      color: #002E4A;
      background: linear-gradient(0deg, #7ED1FF, #7ED1FF), #9EDCFF;
  }
  &.Sold,
  &.CLSD,
  &.Closed { 
      color: #333333;
      background: #E0E0E0;
  }
  background: linear-gradient(0deg, #C2F7AA, #C2F7AA), #B8E;
}
